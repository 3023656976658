import * as React from "react"
import Layout from "../components/layout";
import { Link } from "gatsby"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <h1>404</h1>
      <p>Check your co-ordinate system and try again.</p>
      <p>Hey, while you're here, <Link to="/store">are you interested in buying a copy of my map?</Link></p>
    </Layout>
  )
}

export default NotFoundPage
